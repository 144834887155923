import { IConfig } from "./IConfig"
const isProd = process.env.NODE_ENV === "production"

const DevConfig: IConfig = {
  Env: "Dev",
  SocketServer: "http://localhost:2000",
  YoutubeApiKey: "AIzaSyCgcXo5mfEuppRfCs2pivQOWfSDwHrnPgQ", ////MyFirstProject
  ClientId:
    "129451978980-o6lkhioevr1d6jgc3bigdaoqkbvpgvmt.apps.googleusercontent.com",
  ClientSecret: "10ke3qPj9Pzh_I_qLRGKZBHk"
}

const ProdConfig = {
  Env: "Production",
  SocketServer: "http://localhost:2000",
  YoutubeApiKey: "AIzaSyAyVQ3AvYLAOTSTAvj7SHznfZ15ZS8wog8", //Youtubeapi
  ClientId:
    "129451978980-o6lkhioevr1d6jgc3bigdaoqkbvpgvmt.apps.googleusercontent.com",
  ClientSecret: "10ke3qPj9Pzh_I_qLRGKZBHk"
}
export default isProd ? ProdConfig : DevConfig
//export default ProdConfig
