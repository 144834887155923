import { makeAutoObservable, toJS } from "mobx";
import { Preview } from "../../../Models/Preview";
import { YtSearchResult } from "../../../Models/Youtube";
import { YouTubeService } from "../../../Services/YoutubeService";

export default class Store {
  //private Searcher = new YTSearcher(Config.YoutubeApiKey)
  public IsLoading = false;
  //public IsAuthenticated = false
  public IsAuthenticated = true; // google loging disabled for now
  private AccessToken = "";

  public SearchResult?: YtSearchResult = undefined;
  public SearchTerm = "";
  public Error = "";
  public Previews: Preview[] = [
    {
      id: "PLZAP8XDpl7gfulKkggRPPuREOS6O7yZI8",
      type: "playlist",
      title: "The Pechorsk Exclusion Zone Semi-Official Radio",
      author: "PAK-GG",
      thumbnailUrl:
        "https://i.ytimg.com/vi/j_podzD4cSA/hqdefault.jpg?sqp=-oaymwEXCNACELwBSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLB5vOQ2udQvu8mgJBw1ICbxQXJejg",
      created: new Date(),
    },
    {
      id: "Yy9La6YXNqI",
      type: "video",
      title: "Fallout 4 Radio Songs Diamond City Station Full",
      author: "Sky Gamer",
      thumbnailUrl:
        "https://i.ytimg.com/vi/Yy9La6YXNqI/hq720.jpg?sqp=-oaymwEcCOgCEMoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDX-HT2WOet2ztPwhBq2okCEJnWqg",
      created: new Date(),
    },
    {
      id: "KKkEklLLgS0",
      type: "video",
      title: "Stranger Things Retro Synthwave Mixtape",
      author: "Sky Gamer",
      thumbnailUrl:
        "https://i.ytimg.com/vi/KKkEklLLgS0/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLA7Ttqpr3vJKkavKE9LJWtrZYEbGg",
      created: new Date(),
    },
    {
      id: "PLM3nM2CXPLRpjg5Kgym5rPZ0S7jQKnLWZ",
      type: "playlist",
      title: "GO Weekend Playlists",
      author: "Superdopey",
      thumbnailUrl: "https://i.ytimg.com/vi/M-vpwvdZxG8/mqdefault.jpg",
      created: new Date(),
    },
    {
      id: "EJ08EoFVm2k",
      type: "video",
      title:
        "Armin van Buuren live at @A State Of Trance 950 (Jaarbeurs, Utrecht - The Netherlands)",
      author: "Armin van Buuren",
      thumbnailUrl: "https://i.ytimg.com/vi/EJ08EoFVm2k/mqdefault.jpg",
      created: new Date(),
    },

    //
  ];
  constructor() {
    makeAutoObservable(this);
    //Config.
  }

  public async Search(
    search: string,
    type: "video" | "playlist",
    nextPageToken?: string
  ) {
    this.Error = "";

    this.IsLoading = true;
    this.SearchTerm = search;

    try {
      const result = await YouTubeService.SearchCached(
        search,
        type,
        nextPageToken
      );

      // console.log("search", toJS(result))

      if (nextPageToken === undefined) {
        this.SearchResult = result;
      } else if (this.SearchResult !== undefined) {
        this.SearchResult.items.push(...result.items);
        this.SearchResult.nextPageToken = result.nextPageToken;
        this.SearchResult = { ...this.SearchResult };
      }
    } catch (error) {
      //console.log("error", error)
      this.Error = "An error occured";
    } finally {
      this.IsLoading = false;
    }
  }

  public AuthenticateGoogle(accesToken: string) {
    this.AccessToken = accesToken;
    this.IsAuthenticated = true;
  }

  public ClearSearch() {
    this.SearchResult = undefined;
  }
}
export const searchStore = new Store();
