import { observer } from "mobx-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import { cacheStore } from "./Cache/CacheStore";
import Config from "./Config/Config";
import Search from "./Screens/Youtube/Search/Search";
import SearchResultsPage from "./Screens/Youtube/SearchResultsPage/SearchResultsPage";
import YoutubePlayer from "./Screens/Youtube/YoutubePlayer";

console.log("Env", Config.Env);
//cacheStore
function App() {
  if (!cacheStore.IsHydrated) {
    return null;
  }

  return (
    <Router>
      <Switch>
        {/* <Route path="/player">
            <Player />
          </Route>
          <Route path="/controller">
            <Controller />
          </Route> */}
        <Route path="/play/:type/:id/:title/" exact>
          <YoutubePlayer />
        </Route>
        {/* (/:pageToken) */}
        <Route path="/result/:search/:type/:pageToken?">
          <SearchResultsPage />
        </Route>
        <Route path="/" exact>
          <Search />
        </Route>
      </Switch>
    </Router>
  );
}

export default observer(App);
